import React from "react"
import { SizedText } from "../typography"
import Grid from "../grid"
import Breadcrumbs from "../../molocules/breadcurmbs"



const LargeHeroContainer = ({ padding, background, styles, children, breadcrumbLinks }) => {

  return (
    <Grid.FullWidthSection 
      as="section"
      css={{
        boxShadow: `inset 0px 11px 8px -10px rgba(0,0,0,0.4), inset 0px -11px 8px -10px rgba(0,0,0,0.4)`,
        borderBottom: `1px solid #3e769d`,
        padding: padding || `4rem 0`,
        overflowX: `hidden`,
        ...background,
        ...styles,
      }}
    >

      <Grid.FullWidthSection>
      <Breadcrumbs links={breadcrumbLinks || []} />
        <Grid.Block id="hero" start={[2, 2, 2, 2, 2, 3]} span={[12, 12, 13, 13, 13, 10]}>
          <div css={{
            display: `block`,

            '@media (min-width: 680px)': {
              display: `flex`,
              justifyContent: `space-between`,
              alignItems: `center`,
                '.heroContent': { width: `calc(60% - 1rem)`},
                '.heroImage': { width: `calc(40% - 1rem)`},
              },

              '@media (min-width: 1080px)': {
                display: `flex`,
                '.heroContent': { width: `calc(66% - 1rem)`},
                '.heroImage': { width: `calc(33% - 1rem)`},
              }
              
            }}
          >
            {children}
          </div>
        </Grid.Block>
      </Grid.FullWidthSection>
    </Grid.FullWidthSection>
  )  
}

const LargeHeroImage = ({ styles, children }) => {
  return (
    <div className="heroImage" css={{...styles}}>
      {children}
    </div>
  )
}

const LargeHeroContent = ({ styles, children}) => {
  return (
    <div className="heroContent" css={{...styles}}>
      {children}
    </div>
  )
}

const LargeHeroEyebrow = ({ styles, text}) => {
  return (
    <> 
      <SizedText as="p" 
        smallSize="1.424" 
        largeSize="2.027" 
        styleOverrides={{  
          fontFamily: `Proxima-nova`, 
          fontWeight: `300 !important`, 
          borderBottom: `2px solid #000`, 
          paddingBottom: `0.25rem`, 
          marginBottom: `2rem`, 
          width: `max-content !important`, 
          '@media (min-width: 680px)': {
            borderBottom: `3px solid #000`, 
            paddingBottom: `0.5rem`, 
            marginBottom: `2rem`, 
          },
          ...styles
        }}>{text}</SizedText>
    </>
  )
}


const LargeHero = ({ children }) => <>{children}</>

LargeHero.Container = LargeHeroContainer
LargeHero.Image = LargeHeroImage
LargeHero.Content = LargeHeroContent
LargeHero.Eyebrow = LargeHeroEyebrow

export default LargeHero